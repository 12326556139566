import React, {useEffect, useState} from "react"
import PhoneInput from 'react-phone-number-input/input'
//import { StateSelect } from "./components/StateSelect";

import _uniqueId from 'lodash/uniqueId'
import _ from "lodash"

import './NewForm.css'

const logoURL = 'logo3.png'

//const host = 'http://localhost:7071'
const host = 'https://qa-psrs-functions.azurewebsites.net'
let path = '/psrs/ACHContactUpdate'

let fieldError = {
    vendorNumber: false,
    vendorName: false,
    firstName: false,
    lastName: false,
    email: false, 
    phone: false,
    address1: false,
    address2: false,
    city: false,
    state: false,
    zip: false
}

const ContactForm = () => {


    const [status, setStatus] = useState("Submit")
    const [submitted, setSubmitted] = useState(false)
    const [hasError, setHasError] = useState(false)
    // eslint-disable-next-line
    const [hasZipError, setHasZipError] = useState(false)
    // eslint-disable-next-line
    const [showAddress, setShowAddress] = useState(true)
    const [validEmail, setValidEmail] = useState(true)
    const [emailSuggestion, setEmailSuggestion] = useState("")
    const [emailErrors, setEmailErrors] = useState([])
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line
    const [xfieldError, setFieldError] = useState({
        vendorNumber: false,
        vendorName: false,
        firstName: false,
        lastName: false,
        email: false, 
        phone: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        zip: false
    })

    const [formData, setFormData] = useState({
        vendorNumber: '',
        vendorName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
    })

    const is_int = (value) => {
        if ((parseFloat(value) === parseInt(value)) && !isNaN(value)) {
            return true;
          } else {
            return false;
          }
    }

    /*
    const GetCityState = async (zip) => {

        // check for length of 5
        if ((zip.length === 5) && (is_int(zip))) {
                
            const url = `https://zip.getziptastic.com/v2/US/${zip}`
            fetch(`https://zip.getziptastic.com/v2/US/${zip}`)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                const data = JSON.parse(result)
                if (formData.city === '' || formData.city === null || formData.city !== data.city ) {
                    formData.city = data.city
                    setFormData({...formData, city: data.city})
                }
                if (formData.state === '' || formData.state === null || formData.state !== data.state_short) {
                    formData.state = data.state_short
                    setFormData({...formData, state: data.state_short})
                }                })
            .catch(error => {
                console.log('error', error)
            })
                
        }

    }
   
    const setEmailData =(e) => {

        e.preventDefault(); // 👈️ prevent page refresh

        const field = e.target.name
        const value = e.target.value
        const currentFormData = formData
        currentFormData[field] = ''

        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)


        
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        
        if (re.test(value)) {
            currentFormData[field] = value
        }
        setFormData(currentFormData)
        console.log('xx')

    }
     */


    const setData = (e) => {
        e.preventDefault(); // 👈️ prevent page refresh

        const field = e.target.name
        const value = e.target.value

        const currentFormData = formData
        currentFormData[field] = value
        setFormData(currentFormData)

    }
    const AddressFields = () => {
        console.log('AddressFields...')

        const isFocus = formData.email !== '' && formData.phone !== '' && formData.firstName !== '' && formData.lastName !== '' && showAddress ? true : false

        return(
            <table  className="paleBlueRows">
                <tbody>
                    <tr>
                        <td colSpan={3}>
                            <div>
                   
                                {!isFocus ? 
                                <input 
                                    style={{opacity: 0.8, background: fieldError.address1 ? 'red':''}}
                                    //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                    //required 
                                    disabled={true}
                                    type="text" 
                                    placeholder={formData && formData.address2 ? "" : "Address Line 1"}
                                    defaultValue={formData.address1} 
                                    name="address1" 
                                    id="address1" 
                                    onBlur={setData}
                                />
                                    :
                                <input 
                                    style={{opacity: 0.8, background: fieldError.address1 ? 'red':''}} 
                                    //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                    //required 
                                    disabled={true}
                                    type="text" 
                                    placeholder={formData && formData.address2 ? "" : "Address Line 1"}
                                    defaultValue={formData.address1} 
                                    name="address1" 
                                    id="address1" 
                                    autoFocus 
                                    onBlur={setData}
                                />
                                } 
                                <label htmlFor="address1">Street #1*</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <div>
                                <input 
                                style={{opacity: 0.8, background: fieldError.address2 ? 'red':''}} 
                                //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                disabled={true}
                                type="text" 
                                name="address2" 
                                placeholder={formData && formData.address1 ? "" : "Address Line 2"}
                                defaultValue={formData.address2} 
                                id="address2" onBlur={setData}  
                                />
                                <label htmlFor="address2">Street #2</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                           
                                <input style={{opacity: 0.8, background: fieldError.city ? 'red':''}} 
                                //required 
                                type="text" 
                                //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                placeholder="City" 
                                disabled={true}
                                name="city" id="city" 
                                defaultValue={formData.city} 
                                onBlur={setData} 
                                />
                                <label htmlFor="city">City*</label>
                            {/* 
                            <div className="state-wrap">
                                <input required type="text" name="state" id="state" value={formData.state} onBlur={setData} />
                                <label htmlFor="state">State*</label>
                            </div>
                            */}
                        </td>
                        <td>
                           
                               {/*  <StateSelect 
                                    id="state2" 
                                    //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                    disabled={false}
                                    className="contact-form select" 
                                    value={formData.state}
                                    selected={formData.state}
                                    required={true}
                                    onChange={(value)=>setFormData({...formData, state: value})}
                                /> */}
                                <input style={{opacity: 0.8, background: fieldError.city ? 'red':''}} 
                                    //required 
                                    type="text" 
                                    disabled={true}
                                    //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                    placeholder="State" 
                                    name="state" id="state" 
                                    defaultValue={formData.state} 
                                    onBlur={setData} 
                                />
                                <label htmlFor="state2">State*</label>
                           
                        </td>
                        <td>
                           
                                <input
                                    //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip} 
                                    disabled={true}
                                    placeholder='Zip' 
                                    defaultValue={formData.zip} 
                                    //required 
                                    type="text" 
                                    pattern="[0-9]*" 
                                    maxLength="5" 
                                    name="zip" 
                                    id="zip" 
                                    onChange={()=>false} 
                                    onKeyUp={handleZipCode}
                                />
                                <label htmlFor="zip">Zip*</label>
                           
                        </td>
                    </tr>
                </tbody>
            </table>
                
        )

    }


    const h = window.location.host
    //const l = window.location.href

    const reqOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': '81004d52-06fc-42a6-856d-fd55db6f14df',
            'X-Api-Key': '81004d52-06fc-42a6-856d-fd55db6f14df',
            'Host': h,
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Methods': 'POST',
            //'Access-Control-Allow-Headers: Origin': 'X-Api-Key, Content-Type, Accept, Authorization'
        },
        //credentials: 'include',
        method: 'GET',
        //mode: 'cors',
        redirect: 'follow'
      }
 
    const handleZipCode = async (e) => {
        console.log('handleZipCode...')

        e.preventDefault(); // 👈️ prevent page refresh

        const zip = e.target.value

        // check for length of 5
        if ((zip.length === 5) && (is_int(zip))) {
                    
            //const url = `https://zip.getziptastic.com/v2/US/${zip}`
            fetch(`https://zip.getziptastic.com/v2/US/${zip}`)
            .then(response => response.text())
            .then(result => {

                //console.log('handleZipCode Result->',result)
                
                const data = JSON.parse(result)

                if (data?.message === 'Not Found') {
                    setHasError(false)
                    setHasZipError(true)
                    //setShowAddress(false)
                } else {
                    setHasError(false)
                    setHasZipError(false)

                    formData.zip = data.postal_code
                    setFormData({...formData, zip: data.postal_code})

                    if (formData.city === '' || formData.city === null || formData.city !== data.city ) {
                        formData.city = data.city
                        setFormData({...formData, city: data.city})
                    }
                    if (formData.state === '' || formData.state === null || formData.state !== data.state_short) {
                        formData.state = data.state_short
                        setFormData({...formData, state: data.state_short})
                    }

                    //setShowAddress(true)
                }
            })
            .catch(error => {
                console.log('error', error)
            })

            
        } else {
            setHasError(true)
            setHasZipError(false)
            //setShowAddress(false)
        }

    }

    const handleVendorNumber = async (e) => {
        console.log('handleVendorNumber...')

       // e.preventDefault(); // 👈️ prevent page refresh

       // const vendorNumber = e.target.value 
       setLoading(true)
       const vendorNumber = e

        // call to vendor lookup
        const options = {
            method: 'POST',
            body: JSON.stringify({
                transactionType: 'lookupVendor',
                vendorNumber: vendorNumber
            })
    
        }

        //const DEBUG = true
        //const host = 'http://localhost:5000'
        //const path = '/vendor_lookup'
        //const path = '/update'

        const params = {...reqOptions, ...options}
        const url = `${host}${path}`
        let validVendorData = {}
      
        validVendorData = await fetch(url, params)
            .then(response => response.json())
            .then(result => {
                //console.log('handleVendorNumber Result -> ', result)

                // check for errors etc.. but for now return it?
                //const res = JSON.parse(result)
                return result.resData

            })
            .catch( err => {
                console.log('ERROR->', err)
                return (
                    {
                        status: 500,
                        errors: {
                            errorCode: err.TypeError,
                            errorMessage: 'Fetch Error'
                        }
                    }
                )
            })

        //const validVendorData = await xfetch('/vendor_lookup', options)

        //console.log('validVendor -> ', validVendorData ? `${vendorNumber} - ${validVendorData.status}` : 'none' )

        if (validVendorData && validVendorData.status !== 200) {

    // if (e.target.value !== '38308') {
            if (vendorNumber === '') {

                const resetFormFields = {
                    vendorName: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                }

                fieldError.vendorNumber = false
                setFieldError({...fieldError, vendorNumber: false})
                formData.vendorName = ''
                setFormData({...formData, vendorName: ''})
                formData.vendorNumber = vendorNumber
                setFormData({...formData, vendorNumber: vendorNumber})
                
                setFormData({...formData, ...resetFormFields})

                setStatus('Submit')
                setLoading(false)
                
            } else {

                const errMsg = validVendorData.errors.errorMessage   || 'Not Found'

                const errData = {
                    vendorNumber: vendorNumber,
                    vendorName: errMsg,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                }

                fieldError.vendorNumber = true
                setFieldError({...fieldError, vendorNumber: true})
                
                formData.vendorName = errMsg
                //setFormData({...formData, vendorName: errMsg})
                
                formData.vendorNumber = vendorNumber
                //setFormData({...formData, vendorNumber: vendorNumber})

                formData.email = ''
                setFormData({...formData, ...errData})

                setStatus('Errors')
                setLoading(false)
            }
        

        } else {

            let updateFirstName = validVendorData.data.values.firstName ? validVendorData.data.values.firstName?.trim(): ''
            let updateLastName = validVendorData.data.values.lastName ? validVendorData.data.values.lastName?.trim():''
            let vName = `${updateFirstName} ${updateLastName}`

            if (updateFirstName?.trim().toLowerCase() === 'inc.' || 
                updateFirstName?.trim().toLowerCase() === 'inc'  || 
                updateFirstName?.trim().toLowerCase() === 'llc'  ||
                updateFirstName?.trim().toLowerCase() === 'llc.' ||
                updateFirstName?.trim().toLowerCase() === 'pc'   ||
                updateFirstName?.trim().toLowerCase() === 'pc.'  ||
                updateFirstName?.trim().toLowerCase() === 'lc'   ||
                updateFirstName?.trim().toLowerCase() === 'lc.') {
                    vName = `${updateLastName} ${updateFirstName}`
                    updateFirstName = validVendorData.data.values.lastName
                    updateLastName = validVendorData.data.values.firstName

                }


            const updatedData = {
                vendorNumber: vendorNumber,
                //vendorName: `${validVendorData.data.values.firstName ? validVendorData.data.values.firstName?.trim() : ''} ${validVendorData.data.values.lastName ? validVendorData.data.values.lastName?.trim() : ''}`,
                //firstName: validVendorData.data.values.firstName,
                //lastName: validVendorData.data.values.lastName,
                vendorName: vName,
                firstName: updateFirstName,
                lastName: updateLastName,
                address1: validVendorData.data.values.addr1,
                address2: validVendorData.data.values.addr2,
                city: validVendorData.data.values.city,
                state: validVendorData.data.values.state,
                zip: validVendorData.data.values.zip ,
            }

            // reset all fields to not be an error
           fieldError = {
                vendorNumber: false,
                vendorName: false,
                firstName: false,
                lastName: false,
                email: false, 
                phone: false,
                address1: false,
                address2: false,
                city: false,
                state: false,
                zip: false
            }
            //fieldError.vendorNumber = false
            //setFieldError({...fieldError, vendorNumber: false})
            setFieldError(fieldError)

            formData.vendorNumber = vendorNumber
        // setFormData({...formData, vendorNumber: vendorNumber})

            
            //formData.vendorName = `${validVendorData.data.values?.firstName?.trim()} ${validVendorData.data.values.lastName?.trim()}`
            formData.vendorName = vName
        // setFormData({...formData, vendorName: `${validVendorData.data.values.firstName} ${validVendorData.data.values.lastName}`})

            formData.address1 = validVendorData.data.values.addr1
            formData.address2 = validVendorData.data.values.addr2
            formData.city = validVendorData.data.values.city
            formData.state = validVendorData.data.values.state
            formData.zip = validVendorData.data.values.zip 

            //if (validVendorData.data.values.zip !== '') setShowAddress(true)

            setFormData({...formData, ...updatedData})

            setStatus('Submit')
            setLoading(false)
        }
    }

    const handleEmail = async (e) => {
        console.log('handleEmail...')

        //e.preventDefault(); // 👈️ prevent page refresh

        //const email = e.target.value
        setLoading(true)
        const email = e

        //const path = '/update'
        //const url = `http://localhost:5000/update`
        const url = `${host}${path}`

        const body = {
            transactionType: 'checkEmail',
            name:"",
            message:"",
            email: email
        }
        const options = {
            method: 'POST',
            //headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }

        const params = {...reqOptions, ...options}

        fetch(url,params)
        .then(response => response.text())
        .then(result => {

            //console.log('handleEmailResult -> ',result)
            
            const data = JSON.parse(result).resData


            setValidEmail(data?.valid)
            setEmailErrors(data?.errorMessages)

            if (data?.suggestion !== '') {
                setEmailSuggestion(`Try: ${data?.suggestion}`)
                setFieldError({...fieldError, email: true})
                setHasError(true)
                fieldError = {...fieldError, email: true}
                setLoading(false)

            } 
            
            if (data?.valid) {
                setValidEmail(true)
                setEmailSuggestion('')
                setFieldError({...fieldError, email: false})
                setHasError(false)
                fieldError = {...fieldError, email: false}


                formData.email = data.email
                setFormData({...formData, email: data.email})
                setLoading(false)
            } else {
                setFieldError({...fieldError, email: true})
                setHasError(true)
                fieldError = {...fieldError, email: true}
                setEmailSuggestion(data?.suggestion !== '' ? `Try: ${data?.suggestion}` : '')
                setLoading(false)
            }
        })
        .catch(error => {
            console.log('error', error)
        })


    }

    const _handleVendorNumber = _.debounce(handleVendorNumber, 1000, { trailing: true, leading: false })
    const _handleEmail = _.debounce(handleEmail, 2000, { trailing: true, leading: false })


    const handleSubmit = async (e) => {

        e.preventDefault();

        console.log('handleSubmit...')
        setStatus('Verifying...')
        //console.log(formData)  
        
        // check values
        const submitErrors = {
            vendorNumber: false,
            vendorName: false,
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            address1: false,
            address2: false, 
            city: false,
            state: false,
            zip: false
        }

        if (formData.vendorNumber === '' || formData.vendorNumber === 'null') submitErrors.vendorNumber = true
        if (formData.vendorName === '' || formData.vendorName === 'null') submitErrors.vendorName = true
        //if (formData.firstName === '' || formData.firstName === 'null') submitErrors.firstName = true
        //if (formData.lastName === '' || formData.lastName === 'null') submitErrors.lastName = true
        if (formData.email === '' || formData.email === 'null') submitErrors.email = true
        //if (formData.phone === '' || formData.phone === 'null') submitErrors.phone = true
        //if (formData.address1 === '' || formData.address1 === 'null') submitErrors.address1 = true
        //if (formData.address2 === '' || formData.address2 === 'null') submitErrors.address2 = true
        //if (formData.city === '' || formData.city === 'null') submitErrors.city = true
        //if (formData.state === '' || formData.state === 'null') submitErrors.state = true
        //if (formData.zip === '' || formData.zip === 'null') submitErrors.zip = true


        setFieldError({...fieldError, ...submitErrors})

        fieldError = submitErrors

        /* if (formData.vendorNumber === '' || formData.vendorNumber === 'null') setFieldError({...fieldError, vendorNumber: true})
        if (formData.vendorName === '' || formData.vendorName === 'null') setFieldError({...fieldError, vendorName: true})
        if (formData.firstName === '' || formData.firstName === 'null') setFieldError({...fieldError, firstName: true})
        if (formData.lastName === '' || formData.lastName === 'null') setFieldError({...fieldError, lastName: true})
        if (formData.email === '' || formData.email === 'null') setFieldError({...fieldError, email: true})
        if (formData.phone === '' || formData.phone === 'null') setFieldError({...fieldError, phone: true})
        if (formData.address1 === '' || formData.address1 === 'null') setFieldError({...fieldError, address1: true})
        if (formData.city === '' || formData.city === 'null') setFieldError({...fieldError, city: true})
        if (formData.state === '' || formData.state === 'null') setFieldError({...fieldError, state: true})
        if (formData.zip === '' || formData.zip === 'null') setFieldError({...fieldError, zip: true}) */

            // test
        //formData.vendorNumber=''
        const errors = Object.keys(submitErrors).some(function(k) {
            return submitErrors[k] === true
        })

        if (errors) {
            alert(JSON.stringify('Verify Data and Submit again'))

            setHasError(true)
            setSubmitted(false)
            setStatus('Submit')
            setFieldError({...fieldError, ...submitErrors})
            return false
        }

       //alert(JSON.stringify(formData, null, 3))

        // *** start ***

        // call to vendor lookup
        const options = {
            method: 'POST',
            body: JSON.stringify({
                transactionType: 'updateContact',
                formData
            })
    
        }

        //const DEBUG = true
        //const host = 'http://localhost:5000'
        //const path = '/update_contact'
        //const path = '/update'

        const params = {...reqOptions, ...options}
        const url = `${host}${path}`
        let updateVendorData 

        // eslint-disable-next-line
        updateVendorData = await fetch(url, params)
            .then(response => response.json())
            .then(result => {
                //console.log('updateVendorData Result ->' , result)

                // check for errors etc.. but for now return it?
                //const res = JSON.parse(result)
                return result

            })
            .catch( err => console.log('ERROR->', err))

        //const validVendorData = await xfetch('/vendor_lookup', options)

        //console.log('updateVendor -> ', updateVendorData )

        // *** end ***



        setSubmitted(true)
        return true

    }

    const SubmitErrors = errors => {
       
        const returnList = []
        if (errors.errors.vendorNumber ) returnList.push(<li key={_uniqueId('se_')}>Vendor Number Required</li>)
        if (errors.errors.email ) returnList.push(<li key={_uniqueId('se_')}>Email Required</li>)
        //if (errors.errors.address1 ) returnList.push(<l key={_uniqueId('se_')}>Address 1 Required</l>)
        //if (errors.errors.city ) returnList.push(<li key={_uniqueId('se_')}>City Required</li>)
        //if (errors.errors.state ) returnList.push(<li key={_uniqueId('se_')}>State Required</li>)
        //if (errors.errors.zip ) returnList.push(<li key={_uniqueId('se_')}>Zip Required</li>)

        return (
            <ul className="zip-error">
                {returnList}
            </ul>
         )
    }
    const ListErrors = errors => {
        //const lError = errors.errors.map( e => `<li>${e.error}</li>`)

        return (
                <ul className="zip-error">
                    {errors.errors.map( e => <li key={_uniqueId('le_')}>{e.error}</li>)}
                </ul>
        )
            
    }

/*     useEffect(()=>{
        console.log('re-rendering....')
    }) */

/*     useEffect(()=>{
        console.log('on initial mount rendering....')
    },[]) */

/*     useEffect(()=>{
        console.log(`formData changed -> ${JSON.stringify(formData, null, 2)}`)
        if (formData.address1 !== '' || formData.city !== ''  || formData.zip !== '') {
            setShowAddress(true)
        } else {
            setShowAddress(false)
        }

    },[formData]) */

    useEffect(() => {
        console.log('useEffect hasError -> ', hasError)        
    }, [hasError])

    if (submitted) {
        
        return (
        <>
            <form className="contact-form">
                <fieldset>
                    <div>
                        <legend>Thank you!</legend>
                    </div>
                    <div>
                        <legend >Your information has been sent</legend>
                    </div>
                   
                    <div >
                        <button 
                            type="button"
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            //onSubmit={()=>window.location.reload(false)}
                            onClick={()=> window.location.assign("https://www.oceansideca.org")}
                        >OK
                        </button>
                    </div>
                </fieldset>
            </form>
        </>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="contact-form">
            <div style={{background: '#51AECF'}}>
                <img className="logo" width='50%' height='50%' src={logoURL} alt=''/>
            </div>
            <fieldset>
                <legend>Vendor Update</legend>
                {/*<p className="instructions">Enter Vendor Number</p>*/}
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <input 
                                           style={{opacity: 0.8, background: fieldError.vendorNumber ? 'red':''}} 
                                           required 
                                           //type="number" 
                                           pattern="[0-9]*" 
                                           type="text"
                                           placeholder="Enter Vendor Number" 
                                           name="vendorNumber" 
                                           id="vendorNumber" 
                                           autoComplete="off" 
                                           autoFocus 
                                           defaultValue={formData.vendorNumber}
                                           onChange={(e)=>_handleVendorNumber(e.target.value)} />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {/*<input style={{opacity: 0.8, background: fieldError.vendorNumber ? 'red':''}} required type="text" placeholder="Vendor Name" defaultValue={formData.vendorName} name="vendorName" id="vendorName"  disabled={true} onBlur={setData}/>*/}
                                        <label style={{ opacity: 0.8, fontSize: 28, textAlign: 'left', alignContent: 'center' }} >{formData.vendorName}</label>
                                    </div>
                                </td>
                            </tr>

                        </tbody>       
                    </table>
                    <label htmlFor="vendorNubmer">Vendor Number*</label>
                </div>
                <legend>Contact Info</legend>
                {/*<p className="instructions">Enter Contact Info</p>*/}
               
                <div>
                    <table className="paleBlueRows">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                       <input 
                                           style={{opacity: 0.8, background: (fieldError.email ) ? 'red':''}}
                                           //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                           required 
                                           type="email" 
                                           name="email" 
                                           id="email" 
                                           placeholder="Enter valid email address"
                                           autoComplete="off"
                                           //onBlur={handleEmail} 
                                           onChange={(e)=> _handleEmail(e.target.value)}
                                        />
                                        <label htmlFor="email">Email*</label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <PhoneInput
                                            style={{opacity: 0.8, background: fieldError.phone ? 'red':''}}
                                            //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                            className="contact-form input" 
                                            country="US"
                                            defaultCountry="US"
                                            value={formData.value}
                                            required={false}
                                            placeholder={'(XXX) XXX-XXXX'}
                                            autoComplete="off"
                                            onChange={(value)=>value.length === 12 ? setFormData({...formData, phone: value}):''}
                                        />
                                        {/*<input type="text" name="phone" id="phone" onBlur={(e)=>setFormData({...formData, phone: e.target.value})} />*/}
                                        <label htmlFor="phone">Phone</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <input 
                                           style={{opacity: 0.8, background: fieldError.firstName ? 'red':''}}
                                           //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip} 
                                           defaultValue={formData.firstName} 
                                           type="text" 
                                           placeholder="Enter first name" 
                                           name="firstName" 
                                           id="firstName" 
                                           autoComplete="off"
                                           onChange={()=>true} 
                                           onBlur={(e)=>setFormData({...formData, firstName: e.target.value})} 
                                        />
                                        <label htmlFor="firstName">First name</label>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input style={{opacity: 0.8, background: fieldError.lastName ? 'red':''}} 
                                           defaultValue={formData.lastName} 
                                           //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                                           type="text" 
                                           placeholder="Enter last name" 
                                           name="lastName" id="lastName" 
                                           autoComplete="off"
                                           onChange={()=>true} 
                                           onBlur={(e)=>setFormData({...formData, lastName: e.target.value})} 
                                        />
                                        <label htmlFor="lastName">Last name</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    {!validEmail && emailErrors && emailErrors.length === 0 ? <p className="zip-error">{emailSuggestion}</p>:''}
                                    {!validEmail && emailErrors && emailErrors.length > 0 ? <ListErrors errors={emailErrors} /> : '' }
                                    {hasError && fieldError? <SubmitErrors errors = {fieldError} /> : '' }
                                    {loading && <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   
                </div>
                <legend>Address</legend>
                { !showAddress ? <p className="instructions">Start by entering your zip code.</p> :''}
                <p className="instructions">If you need to update the address listed please email <strong><a href = "mailto: OHAAccounting@oceansideca.org">OHAAccounting@oceansideca.org</a></strong> for the address change form.</p>
                <div>
                    {showAddress ? <AddressFields /> : ''}
                   {/*  <div className="zip-wrap">
                        <input
                           //disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip} 
                           placeholder='Enter Zip' 
                           defaultValue={formData.zip} 
                           required type="text" 
                           pattern="[0-9]*" 
                           maxLength="5" 
                           name="zip" 
                           id="zip" 
                           onChange={()=>false} 
                           onKeyUp={handleZipCode}
                        />
                        <label htmlFor="zip">Zip*</label>
                        {hasZipError ? <p className="zip-error">Not a real zip code.</p>:''}
                    </div>        */} 
                </div>
                {/* 
                <div className="submit-wrap">
                    <input type="submit" value="Submit" />
                </div>
                */}   
                <div  className="mb-3 pt-0 ">
                    <button 
                        name="reset"
                        type="reset"
                        value="Reset"
                        style={{backgroundColor: '#4cc9f0'}}
                        className="text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={()=> {

                            setValidEmail(true)
                            setEmailErrors([])
                            setHasError(false)


                            fieldError = {
                                vendorNumber: false,
                                vendorName: false,
                                firstName: false,
                                lastName: false,
                                email: false, 
                                phone: false,
                                address1: false,
                                address2: false,
                                city: false,
                                state: false,
                                zip: false
                            }

                            const clearData = {
                                vendorNumber: '',
                                vendorName:'',
                                firstName: '',
                                lastName:'',
                                address1:'',
                                address2:'',
                                city:'',
                                state:'',
                                zip:'',
                                phone:'',
                                email:'',

                            }
                            

                            setFormData({...formData, ...clearData })
                        }}
                    > Clear
                    </button>
                    <button 
                        type="submit"
                        disabled={fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip}
                        className= {fieldError.vendorNumber || fieldError.email || fieldError.address1 || fieldError.city || fieldError.state || fieldError.zip ?
                          "btn two bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            :
                          "bg-blue-500 text-white active:bg-blue-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        }
                    >{status}
                    </button>
                </div>

            </fieldset>
         </form>
    )



}

export default ContactForm